var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.lista_caratulas,"loading":_vm.cargando_caratulas,"loading-text":"Cargando sus carátulas... espere un momento","item-class":_vm.itemRowBack,"footer-props":{
            'items-per-page-text': 'Ítems por página',
            'items-per-page-all-text': 'Todo',
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('p',{staticClass:"d-flex align-center mb-0"},[_c('v-icon',{staticClass:"pr-1",attrs:{"color":"info"}},[_vm._v("mdi-file-document-outline")]),_vm._v(" Mis Carátulas ")],1)]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer')],1)]},proxy:true},{key:"item.ult_estado",fn:function(ref){
          var item = ref.item;
return [(item.estados[item.estados.length -1].id_estado_caratula_FK !== 1002)?_c('span',[_vm._v(_vm._s(item.estados[item.estados.length - 1].estado_caratula))]):_vm._e(),(item.estados[item.estados.length -1].id_estado_caratula_FK === 1002)?_c('span',[_vm._v("Carátula firmada electrónicamente")]):_vm._e()]}},{key:"item.estado",fn:function(ref){
          var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[(item.saldo == 0)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("De momento no existen saldos pendientes")])])],1):_vm._e(),(item.saldo != 0)?_c('div',{staticClass:"d"},[_c('div',[(item.estado_saldo)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("el saldo fue pagado")])])],1):_vm._e(),(!item.estado_saldo)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v("Saldo pendiente")])])],1):_vm._e()])]):_vm._e(),(item.hasOwnProperty('otroSaldo'))?_c('div',[(item.otroSaldo.estado)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("Saldo extra pagado")])])],1):_vm._e(),(!item.otroSaldo.estado)?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"warning","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert-circle ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.otroSaldo.motivo))])])],1):_vm._e()]):_vm._e(),(!item.hasOwnProperty('otroSaldo'))?_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success","dark":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("De momento no existen saldos relacionados a reajustes por autocotización")])])],1):_vm._e()])]}},{key:"item.fecha_ultimo_estado_caratula",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("toDate")(item.fecha_ultimo_estado_caratula))+" ")]}},{key:"item.ver",fn:function(ref){
          var item = ref.item;
return [_c('a',{on:{"click":function($event){return _vm.verDetalle(item)}}},[(item === _vm.caratula_seleccionada)?_c('v-icon',{attrs:{"color":"info"}},[_vm._v("mdi-text-box-search-outline")]):_vm._e(),(item !== _vm.caratula_seleccionada)?_c('v-icon',{attrs:{"color":"success"}},[_vm._v("mdi-text-box-search-outline")]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No se han registrado compras ")]},proxy:true},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" al "+_vm._s(props.pageStop)+" de "+_vm._s(props.itemsLength)+" ")]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('mis-caratulas-modal-caratula',{attrs:{"ver-dialog":_vm.verDialogCaratula,"caratula":_vm.caratula_seleccionada == null ? {} : _vm.caratula_seleccionada},on:{"ocultarDialog":function($event){_vm.verDialogCaratula = false},"updateClienteRetiro":_vm.updateClienteRetiro}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }