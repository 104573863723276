<template>
  <v-card>
    <v-alert
        color="warning"
        dense
        text
        :icon="false"
        class="text-center font-md"
    >
      <div>Esta carátula posee un saldo a pagar de
      {{ caratula.otroSaldo.monto| clp }}</div>
      <div>Motivo: {{caratula.otroSaldo.motivo}}</div>
      <v-btn color="info" block class="my-2" x-small
             @click="pagarSaldoAdicional(caratula)"><i class="fa fa-credit-card-alt mr-1"></i>Ir
        a Pagar
      </v-btn>
    </v-alert>
    <modal-pago-otro-saldo :ver-dialog="ver_modal_pago_saldo"
                      :caratula="caratula_seleccionada"
                      @ocultarDialog="ver_modal_pago_saldo = false"
                      :mediosPagosQuitar="tiposPagosDeshabilitados"></modal-pago-otro-saldo>
  </v-card>
</template>

<script>
import {ID_TIPO_PAGO} from "@/model/carro/carro.entity";
import ModalPagoOtroSaldo from "@/views/index/cliente/caratulas/ModalPagoOtroSaldo";
export default {
  name: "MisCaratulasDetalleOtroSaldo",
  components: {ModalPagoOtroSaldo},
  props:{
    caratula:{default:Object, type:Object}
  },
  data(){
    return {
      tiposPagosDeshabilitados:[{id_tipo_pago:ID_TIPO_PAGO.KHIPU}, {id_tipo_pago:ID_TIPO_PAGO.KHIPU_WEB}],
      ver_modal_pago_saldo:false,
      keyCount: 0,
      load: {
        tipos_pagos: false
      },
      caratula_seleccionada:null,
    }
  },
  methods: {
    pagarSaldoAdicional(caratula) {
      this.caratula_seleccionada = caratula;
      this.ver_modal_pago_saldo = true;
    },
  }
}
</script>

<style scoped>

</style>