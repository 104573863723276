<template>
  <v-dialog max-width="700" v-model="ver">
    <v-card v-if="compra != null">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <h3 class="font-weight-medium text-center mb-4 mt-2">Información de la compra</h3>
            <v-divider></v-divider>
            <p class="mt-4"><span class="font-weight-medium">N° de carro de compras: </span> {{
                compra.id_carro_solicitud_cliente
              }}</p>
            <p><span class="font-weight-medium">Fecha de creación: </span> {{ compra.fecha_creacion | fullDate }}</p>
            <p><span class="font-weight-medium">Precio total (incluye recargo del recaudador): </span>
              <span v-if="compra.precio_total == 0">Por cotizar</span>
              <span v-if="compra.precio_total > 0">{{ compra.precio_total | clp }}</span>
            </p>
            <p class="mb-0 "><span class="font-weight-medium">Estado del carro: </span>
              <span v-if="compra.pagado && carroEntity.verificarSolicitudesDeCarroPagadas(compra)"
                    class="success--text ">
                <v-icon class="pr-1" color="success">mdi-check-circle-outline</v-icon>finalizado</span>
              <span v-if="compra.pagado && !carroEntity.verificarSolicitudesDeCarroPagadas(compra)"
                    class="warning--text ">
                <v-icon class="pr-1" color="warning">mdi-check-circle-outline</v-icon>finalizado parcialmente</span>
              <span v-if="!compra.pagado" class="error--text ">pendiente</span>
            </p>

            <div class="text-center pt-2" v-if="!compra.pagado && precio_cotizado">
              <v-btn outlined color="info" small @click="continuarCompra()">Ir a pagar
                <v-icon class="pl-1" small color="info">mdi-cart-arrow-right</v-icon>
              </v-btn>
            </div>
            <v-divider class="mt-4"></v-divider>
            <h4 class="text-center font-weight-medium my-4">Solicitudes del carro de compras</h4>
            <v-divider class="mb-4"></v-divider>

            <div class="mb-5" v-for="sol in compra.solicitudes_cliente" :key="sol.id_solicitud_cliente">

              <v-alert outlined color="primary" :icon="false" dense>
                <p class="text-center pt-2 mb-2">Solicitud N°{{ sol.id_solicitud_cliente }}</p>
                <v-divider></v-divider>
                <v-row class="px-2 pb-4 font-md" style="color: rgba(0, 0, 0, 0.6);">
                  <v-col cols="12">
                    <p><span class="font-weight-medium">Forma de retiro: </span> {{ sol.tipo_retiro }}</p>
                    <p class="font-weight-medium" v-if="sol.id_tipo_retiro_FK != ID_TIPO_RETIRO.PRESENCIAL">
                      <span v-if="sol.id_tipo_retiro_FK == ID_TIPO_RETIRO.VALIJA_ELECTRONICA">Correo</span>
                      <span v-if="sol.id_tipo_retiro_FK == ID_TIPO_RETIRO.CHILEXPRESS">Direccion</span>
                      <span v-if="sol.id_tipo_retiro_FK != ID_TIPO_RETIRO.PRESENCIAL">: {{ sol.direccion_envio }}</span>

                    </p>
                    <p><span class="font-weight-medium">Registro: </span>{{ sol.documentosSolicitud[0].tipo_registro }}
                    </p>
                  </v-col>

                  <v-col cols="6" class="py-1 pl-0 d-none d-md-flex">
                    <p class="mb-0 font-weight-medium pl-2">Documentos</p>
                  </v-col>
                  <v-col cols="2" class="py-1 d-none d-md-flex justify-center">
                    <p v-show="sol.documentosSolicitud[0].id_tipo_registro_FK != ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION"
                       class="mb-0 font-weight-medium text-center">Índice</p>
                  </v-col>
                  <v-col cols="2" class="py-1 d-none d-md-flex justify-center">
                    <p class="mb-0 font-weight-medium text-center">Cantidad</p>
                  </v-col>
                  <v-col cols="2" class="py-1 pr-0 d-none d-md-flex justify-center">
                    <p class="mb-0 font-weight-medium text-center">Precio</p>
                  </v-col>
                  <v-col cols="12" class="py-0 px-0 hoverable-2"
                         v-for="(certificado, index) in sol.documentosSolicitud"
                         :key="index">

                    <v-divider class="mx-1"></v-divider>
                    <v-row class="px-3">
                      <v-col cols="12" md="6" class="py-2">
                        <p class="mb-0 pl-md-2 pr-1 text-center text-md-left">

                          {{ certificado.tipo_certificado }} <span
                            v-if="certificado.vigencia != -1">({{ certificado.vigencia }} Años)</span>
                        </p>
                      </v-col>
                      <v-col cols="12" md="2" class="d-flex align-md-center justify-md-center py-2">
                        <span class="d-flex d-md-none font-weight-medium pr-1">Índice:</span>
                        <span
                            v-show="sol.documentosSolicitud[0].id_tipo_registro_FK != ID_TIPO_REGISTRO.ALZAMIENTO_Y_CANCELACION">
                          {{ certificado.indice }}
                        </span>
                      </v-col>
                      <v-col cols="12" md="2" class="d-flex align-md-center justify-md-center py-2">
                        <span class="d-flex d-md-none font-weight-medium pr-1">Cantidad:</span>
                        {{ certificado.numero_copias }}
                      </v-col>
                      <v-col cols="12" md="2" class="d-flex align-md-center justify-md-center py-2">
                        <span class="d-flex justify-center d-md-none font-weight-medium pr-1">Precio:</span>
                        <span v-if="!precio_cotizado">Por cotizar</span>
                        <span v-if="precio_cotizado">{{ certificado.precio_pagado | clp }}</span>
                      </v-col>
                    </v-row>

                  </v-col>

                  <v-col cols="12" class="px-1 pb-0" v-if="sol.documentosSolicitud[0].alzamiento != null">
                    <v-row class="px-3">
                      <v-col cols="12">
                        <v-divider></v-divider>
                        <p class="font-weight-medium mb-0 mt-3 text-center">Datos de alzamiento</p>
                      </v-col>
                      <v-col cols="12" md="6" class="px-1 pt-1">
                        <p class="pl-2 text--black mb-3"><span class="font-weight-medium">Notario titular:</span>
                          {{ sol.documentosSolicitud[0].alzamiento.notaria }}</p>
                        <p class="pl-2 text--black mb-0"><span class="font-weight-medium">Código de verificación:</span>
                          {{ sol.documentosSolicitud[0].alzamiento.id_documento_electronico }}</p>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-1">
                        <p class="pl-md-2 text--black mb-3"><span class="font-weight-medium">Repertorio:</span>
                          {{ sol.documentosSolicitud[0].alzamiento.repertorio }}</p>
                        <p class="pl-md-2 text--black mb-0"><span class="font-weight-medium">Partes:</span>
                          {{ sol.documentosSolicitud[0].alzamiento.partes }}</p>
                      </v-col>
                    </v-row>

                  </v-col>

                  <v-col cols="12" class="px-1 pb-0" v-if="sol.datos_adicionales">
                    <v-divider></v-divider>
                    <v-row class="px-3">
                      <v-col cols="12" class="pb-3">

                        <p class="font-weight-medium mb-0 mt-3 text-center">Datos adicionales</p>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-2 pb-0" v-for="da in sol.datos_adicionales" :key="da.nombre">
                        <p class="mb-0">
                          <span class="font-weight-medium">{{ da.nombre | cleanDatoAdicional}}</span>:
                          <span v-if="da.nombre.toLowerCase() == 'fecha'">{{ da.valor | toDate }}</span>
                          <span v-else-if="da.nombre.toLowerCase() == 'capital'">{{ da.valor | clp }}</span>
                          <span v-else>{{ da.valor }}</span>
                        </p>
                      </v-col>
                    </v-row>
                    <v-divider class="mt-2"></v-divider>
                  </v-col>

                  <v-col cols="12" class="text-right">

                    <p class=" mb-1"><span class="font-weight-medium">Subtotal:</span>
                      <span v-if="precio_cotizado">{{ getPrecioSolicitud(sol) | clp }}</span>
                      <span v-if="!precio_cotizado"> Por cotizar </span>
                    </p>
                    <p class="mb-1" v-if="sol.id_tipo_retiro_FK == ID_TIPO_RETIRO.CHILEXPRESS"><span
                        class="font-weight-medium">Envío por Chilexpress:</span> {{ PRECIO_CHILEXPRESS | clp }}</p>
                    <!--                    <p class="font-weight-medium mb-1" style="font-size: 16px">Total: {{ sol.precio_total | clp }}</p>-->
                    <!--                    <p class="font-weight-medium mb-1" style="font-size: 16px">Total: {{ sol.precio_total | clp }}</p>-->
                  </v-col>
                  <v-col cols="12" class="text-center" v-if="sol.pago_ok">
                    <h3 class="success--text mb-0 font-weight-medium">Pagada
                      <v-icon class="pr-1" color="success">mdi-check-circle-outline</v-icon>
                    </h3>
                  </v-col>
                  <v-col cols="12" v-if="sol.observacion.trim().length > 0">
                    <p class="font-weight-bold mb-2">Observaciones</p>
                    <p class="text-justify mb-0">{{ sol.observacion }}</p>
                  </v-col>
                </v-row>
              </v-alert>


            </div>

          </v-col>
          <v-col cols="12" class="text-center pt-0">
            <v-btn outlined color="primary" small @click="hideDialog">
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>
import {ID_TIPO_RETIRO} from "@/model/carro/carro.entity";
import {PRECIO_CHILEXPRESS} from "@/model/carro/carro.entity";
import {ID_TIPO_REGISTRO} from "@/model/registros/registros.entity";
import carroEntity from '@/model/carro/carro.entity';

export default {
  name: "ComprasDialogVerSolicitudes",
  props: {
    verDialog: {default: false},
    time: {default: null},
    tipo: {
      default: "info"
    },
    compra: {default: null},
    precio_cotizado: {default: true}
  },
  data() {
    return {
      ver: false,
      ID_TIPO_RETIRO: ID_TIPO_RETIRO,
      PRECIO_CHILEXPRESS: PRECIO_CHILEXPRESS,
      ID_TIPO_REGISTRO: ID_TIPO_REGISTRO,
      alzamiento: null,
      carroEntity
    }
  },

  watch: {
    compra() {
      this.alzamiento = null;
      console.log("compra:", this.compra)
      this.compra.solicitudes_cliente.map(sol => {
        if (sol.documentosSolicitud[0].alzamiento != null) {
          this.alzamiento = sol.documentosSolicitud[0].alzamiento
        }
      })
    },
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    }
  },

  methods: {


    obtenerAlzamientoCompra() {
      console.log("Mi Compra : ", this.compra);
    },
    continuarCompra() {
      this.$emit("continuarCompra", this.compra);
    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    getPrecioSolicitud(solicitud) {
      if (solicitud != null) {

        let precio = 0;
        if (solicitud.id_tipo_retiro_FK == ID_TIPO_RETIRO.CHILEXPRESS) {
          precio = 3500;
        }
        solicitud.documentosSolicitud.map(s => {
          precio += s.precio_pagado;
        })

        return precio;
      } else {
        return 0;
      }
    }
  }
}
</script>

<style scoped>

</style>
