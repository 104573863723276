<template>
  <div>
    <v-row>
      <v-col cols="12">

        <v-data-table
            :headers="headers"
            :items="lista_caratulas"
            :loading="cargando_caratulas"
            loading-text="Cargando sus carátulas... espere un momento"
            :item-class="itemRowBack"
            :footer-props="{
              'items-per-page-text': 'Ítems por página',
              'items-per-page-all-text': 'Todo',
            }"
        >

          <!-- PARTE SUPERIOR DE LA TABLA -->

          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>
                <p class="d-flex align-center mb-0">
                  <v-icon color="info" class="pr-1">mdi-file-document-outline</v-icon>
                  Mis Carátulas
                </p>
              </v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <!-- FECHA DE CREACION DE LA CARATULA -->

          <template v-slot:item.ult_estado="{item}">

            <span v-if="item.estados[item.estados.length -1].id_estado_caratula_FK !== 1002">{{
                item.estados[item.estados.length - 1].estado_caratula
              }}</span>
            <span v-if="item.estados[item.estados.length -1].id_estado_caratula_FK === 1002">Carátula firmada electrónicamente</span>
          </template>

          <!--     ESTADO DE LA CARATULA -->
          <template v-slot:item.estado="{item}">
            <div class="d-flex justify-center">
              <!-- No se genero saldo en la caratula-->
              <div v-if="item.saldo == 0">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="success"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-check
                    </v-icon>
                  </template>
                  <span>De momento no existen saldos pendientes</span>
                </v-tooltip>
              </div>

              <!--  se genero un saldo en la caratula -->
              <div v-if="item.saldo != 0" class="d">
                <div>
                  <div v-if="item.estado_saldo">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="success"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-check
                        </v-icon>
                      </template>
                      <span>el saldo fue pagado</span>
                    </v-tooltip>
                  </div>
                  <div v-if="!item.estado_saldo">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                            color="warning"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                          mdi-alert-circle
                        </v-icon>
                      </template>
                      <span>Saldo pendiente</span>
                    </v-tooltip>
                    </div>
                </div>
              </div>

              <!--  Otros saldos, si viene en el obj se genera un saldo extra a pagar o se muestra si ya fue pagado -->
              <div v-if="item.hasOwnProperty('otroSaldo')">
                <div v-if="item.otroSaldo.estado">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          color="success"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-check
                      </v-icon>
                    </template>
                    <span>Saldo extra pagado</span>
                  </v-tooltip>
                </div>
                <div v-if="!item.otroSaldo.estado">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                          color="warning"
                          dark
                          v-bind="attrs"
                          v-on="on"
                      >
                        mdi-alert-circle
                      </v-icon>
                    </template>
                    <span>{{ item.otroSaldo.motivo }}</span>
                  </v-tooltip>
                </div>
              </div>

              <!-- si otro saldo no viene en el obj entonces se mustra un check -->
              <div v-if="!item.hasOwnProperty('otroSaldo')">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                        color="success"
                        dark
                        v-bind="attrs"
                        v-on="on"
                    >
                      mdi-check
                    </v-icon>
                  </template>
                  <span>De momento no existen saldos relacionados a reajustes por autocotización</span>
                </v-tooltip>
              </div>
            </div>
          </template>

          <!-- ULTIMA MODIFICACION -->

          <template v-slot:item.fecha_ultimo_estado_caratula="{item}">

            {{ item.fecha_ultimo_estado_caratula | toDate }}
          </template>

          <!-- BOTON PARA VER LOS DOCUMENTOS -->

          <template v-slot:item.ver="{item}">

            <a @click="verDetalle(item)">
              <v-icon v-if="item === caratula_seleccionada" color="info">mdi-text-box-search-outline</v-icon>
              <v-icon v-if="item !== caratula_seleccionada" color="success">mdi-text-box-search-outline</v-icon>
            </a>

          </template>

          <!-- TABLA SIN DATOS -->

          <template v-slot:no-data>
            No se han registrado compras
          </template>

          <template #footer.page-text="props">
            {{ props.pageStart }} al {{ props.pageStop }} de {{ props.itemsLength }}
          </template>

        </v-data-table>

      </v-col>
      <v-col cols="12" md="5">
        <mis-caratulas-modal-caratula :ver-dialog="verDialogCaratula" @ocultarDialog="verDialogCaratula = false"
                                      :caratula="caratula_seleccionada == null ? {} : caratula_seleccionada"
                                      @updateClienteRetiro="updateClienteRetiro"></mis-caratulas-modal-caratula>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import caratulaEntity, {ID_ESTADO_CARATULA} from "@/model/caratula/caratula.entity";
import MisCaratulasModalCaratula from "@/views/index/cliente/caratulas/MisCaratulasModalCaratula";
import {ID_TIPO_RETIRO} from "@/model/carro/carro.entity";
import {RUT_USUARIO} from "@/model/usuario/usuario.entity";

export default {
  name: "MisCaratulasView",
  components: {MisCaratulasModalCaratula},
  data() {
    return {
      ID_ESTADO_CARATULA: ID_ESTADO_CARATULA,
      ID_TIPO_RETIRO: ID_TIPO_RETIRO,
      RUT_USUARIO: RUT_USUARIO,
      headers: [
        {text: 'Carátula', align: 'center', value: 'numero_caratula', sortable: false, width: "15%"},

        {text: 'Estado', value: 'estado', align: 'center', width: '10%', sortable: false},
        {text: 'Últ. Modificación', value: 'fecha_ultimo_estado_caratula', align: 'center', sortable: false},
        {text: 'Último Estado', value: 'ult_estado', align: 'center', sortable: false},
        {text: 'Ver', value: 'ver', align: 'center', sortable: false},
      ],
      cargando_caratulas: false,
      lista_caratulas: [],
      caratula_seleccionada: null,
      verDialogCaratula: false
    }
  },
  created() {
    this.obtenerMisCaratulas();
  },
  methods: {
    evaluarParaColorNaranja(item) {
      if ((item.id_tipo_retiro === ID_TIPO_RETIRO.VALIJA_ELECTRONICA || (item.id_tipo_retiro !== ID_TIPO_RETIRO.VALIJA_ELECTRONICA && item.rut_usuario_cbrls === RUT_USUARIO.WEB)) &&
          item.id_estado_caratula_FK !== ID_ESTADO_CARATULA.LISTA_PARA_FIRMAR && item.id_tipo_retiro === ID_TIPO_RETIRO.VALIJA_ELECTRONICA) {
        return true
      } else {
        return false
      }
    },
    updateClienteRetiro(cliente) {
      this.caratula_seleccionada.cliente_retiro = cliente;
    },

    obtenerMisCaratulas() {
      this.cargando_caratulas = true;
      caratulaEntity.getMisCaratulas().then(response => {

        this.cargando_caratulas = false;
        this.lista_caratulas = response

        if (this.$route.params.numero_caratula != null) {
          this.caratula_seleccionada = this.lista_caratulas.filter(f => {
            return f.numero_caratula == this.$route.params.numero_caratula
          })[0];
        }

        if (this.caratula_seleccionada == null) {
          this.caratula_seleccionada = this.lista_caratulas[0];
        }
      })
    },
    verDetalle(item) {
      this.caratula_seleccionada = item;
      this.verDialogCaratula = true;
    },
    itemRowBack(item) {
      if (item.numero_caratula === this.caratula_seleccionada.numero_caratula) {
        return "blue lighten-5"
      }
    }
  }
}
</script>

<style scoped>

</style>
