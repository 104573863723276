<template>
  <v-dialog max-width="570" v-model="ver" persistent>
    <v-card v-if=" caratula != null">
      <v-card-text v-if="!validandoPago  && !isSaldoPagado">
        <v-row v-if="!redireccionando">
          <v-col cols="12">
            <modal-pago-medios-pago
                :key="keyCount"
                :ayuda="true"
                :loading="load.tipos_pagos"
                @updateTipoPago="updateTipoPago($event)"
                :ocultarOpciones="mediosPagosQuitar"
                :precio_total_carro="caratula.saldo"
            >

            </modal-pago-medios-pago>
            <v-divider></v-divider>
          </v-col>

          <v-col cols="12" v-if="tipo_pago_seleccionado == null">
            <p class="mb-0 text-center text-sm ">Porfavor, seleccione un método de pago.</p>
          </v-col>
          <v-col cols="6" class="pl-7 pt-0" v-if="tipo_pago_seleccionado != null">
            <p class="mb-0">Subtotal: {{ caratula.saldo | clp }}</p>
            <p class="mb-0">Comisión recaudador: {{ comision | clp }}</p>
          </v-col>
          <v-col cols="6" class="d-flex justify-end pr-7 pt-0" v-if="tipo_pago_seleccionado != null">
            <p class="font-sm mb-0 mt-auto font-weight-bold" style="font-size: 16px">Total: {{
                precio_total | clp
              }}</p>
          </v-col>
          <v-col cols="12" class="text-right">
            <v-btn color="grey" outlined class="mr-3" @click="hideDialog()" :disabled="redireccionando" small>
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>
            <v-btn color="success" outlined class="px-10" :disabled="tipo_pago_seleccionado == null"
                   @click="enviarFormulario()"
                   :loading="redireccionando" small>
              <v-icon class="pr-1" small>mdi-credit-card-multiple-outline</v-icon>
              Pagar
            </v-btn>
          </v-col>
        </v-row>

        <div v-show="redireccionando">
          <modal-pago-pantalla-carga :tipo_pago_seleccionado="tipo_pago_seleccionado"></modal-pago-pantalla-carga>
        </div>

      </v-card-text>


      <v-card-text v-if="!validandoPago  && isSaldoPagado">

        <div class="text-center">
          <v-icon x-large color="success" class="pt-3">mdi-check-all</v-icon>
        </div>
        <p class="text-center pt-3">
          Pago Verificado
        </p>
        <div class="text-center">
          <v-btn color="success" outlined class="px-10" small @click="hideDialog()">
            <v-icon small left>mdi-close</v-icon>
            Volver
          </v-btn>
        </div>
      </v-card-text>


      <v-card-text v-if="validandoPago">
        <div class="text-center">
          <p class="text-center pt-5 pb-3">Verificando el estado de su saldo, por favor, espere un momento...</p>
          <v-progress-circular
              :size="50"
              color="primary"
              class="mb-4"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-row>
          <v-col cols="12" class="text-right">
            <v-btn color="grey" outlined class="mr-3" @click="hideDialog()" small>
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>
            <v-btn small color="success" outlined class="px-10" disabled>
              <v-icon class="pr-1" small>mdi-credit-card-multiple-outline</v-icon>
              Pagar
            </v-btn>
          </v-col>

        </v-row>

      </v-card-text>
    </v-card>

  </v-dialog>
</template>

<script>
import ModalPagoMediosPago from "@/views/index/carro/modal-pago/ModalPagoMediosPago";
import caratulaEntity from "../../../../model/caratula/caratula.entity";
import carroEntity from "../../../../model/carro/carro.entity";
import ModalPagoPantallaCarga from "@/views/index/carro/modal-pago/ModalPagoPantallaCarga";

export default {
  name: "ModalPagoSaldo",
  components: {ModalPagoPantallaCarga, ModalPagoMediosPago},
  props: {
    verDialog: {default: false},
    time: {default: null},
    caratula: {default: null},
    mediosPagosQuitar:{type:Array , default:Array}
  },
  data() {
    return {
      ver: false,
      isLoading: false,
      formularioValido: false,
      dataFormulario: {},
      redireccionando: false,
      valido: false,
      precio_total: 0,
      comision: 0,
      tipos_pagos: [],
      load: {
        tipos_pagos: false
      },
      keyCount: 0,
      tipo_pago_seleccionado: null,
      validandoPago: false,
      isSaldoPagado: false
    }
  },
  watch: {
    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      } else {

        this.validandoPago = true;

        caratulaEntity.getEstadoPagoSaldoTgr(this.caratula.numero_caratula).then(res => {
          this.validandoPago = false;
          if (res) {
            this.isSaldoPagado = true;
            this.$emit("setSaldoPagado");
          } else {
            this.isSaldoPagado = false;
            this.tipo_pago_seleccionado = null;
            this.keyCount++;
          }
        })


      }
    },
    caratula() {
      if (this.tipos_pagos.length == 0) {
        this.getTiposPagos();
      }
    }

  },
  methods: {
    getTiposPagos() {
      console.log("llega a cargar los tipos de pagos")
      this.load.tipos_pagos = true;
      carroEntity.getTiposPagos().then(res => {
        this.load.tipos_pagos = false;
        this.tipos_pagos = res;
      })
    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    updateTipoPago(id_tipo_pago) {
      this.tipo_pago_seleccionado = id_tipo_pago;
      this.getPrecioTotal();
    },
    enviarFormulario() {
      this.redireccionando = true;
      caratulaEntity.getLinkPagoSaldo(this.caratula.numero_caratula, this.tipo_pago_seleccionado).then(resp => {
        location.href = resp
      })

    },
    isFormularioValido() {
      if (this.dataFormulario == null) {
        return false;
      }
      return this.dataFormulario.id_tipo_pago != null;
    },
    getComision() {

      let tot = parseInt(this.caratula.saldo);

      let comision = this.tipos_pagos.find(t => t.id_tipo_pago === parseInt(this.tipo_pago_seleccionado)).comision;
      this.comision = parseInt(tot * (comision - 1))
    },
    getPrecioTotal() {
      if (this.caratula != null && this.tipo_pago_seleccionado != null) {
        let tot = parseInt(this.caratula.saldo);

        let comision = this.tipos_pagos.find(t => t.id_tipo_pago === parseInt(this.tipo_pago_seleccionado)).comision;
        tot = parseInt(tot * comision)

        this.precio_total = tot
        this.getComision();
      }

    },


  }
}
</script>

<style scoped>

</style>
