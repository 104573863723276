<template>
  <v-dialog max-width="570" v-model="ver" persistent>
    <v-card>
      <v-card-text>

        <h3 class=" pb-3 font-weight-medium pt-4">Asignar un nuevo cliente para el retiro de la carátula
          <div class="float-right"><a @click="hideDialog">
            <v-icon>mdi-close</v-icon>
          </a></div>
        </h3>

        <v-divider></v-divider>
        <v-alert text color="info" class="text-center mt-4">Aviso: El cliente que desee asignar para el retiro de la
          carátula debe estar registrado
          en nuestro Portal Web
        </v-alert>

        <v-form @submit.prevent="validarCliente">
          <v-row>

            <v-col cols="8">
              <v-text-field
                  :change="formatRut()"
                  :disabled="cliente_encontrado != null"
                  id="buscadorCliente"
                  autofocus
                  :error-messages="errors.errorsRut($v.rut)"
                  @input="$v.rut.$touch()"
                  @blur="$v.rut.$touch()"
                  outlined dense hide-details
                  label="Rut cliente"
                  v-model="rut"></v-text-field>
            </v-col>
            <v-col cols="4" class="d-flex align-center">
              <v-btn type="submit" :disabled="cliente_encontrado != null || errors.errorsRut($v.rut).length > 0 || rut.length < 11 || rut.length > 12" block color="info">
                <v-icon left small>mdi-magnify</v-icon>
                Buscar
              </v-btn>
            </v-col>
          </v-row>
        </v-form>

        <v-fade-transition>

          <div v-if="cliente_buscado && cliente_encontrado == null">
            <v-divider></v-divider>
            <v-alert text outlined rounded color="red" class="text-center mt-3">El cliente buscado no se encuetra
              registrado
            </v-alert>
          </div>
        </v-fade-transition>

        <v-fade-transition>
          <div v-if="cliente_buscado && cliente_encontrado != null">
            <v-divider class="my-3"></v-divider>
            <h4 class="text-center font-weight-medium"> Nombre del cliente: </h4>
            <p class="text-center" style="text-transform: uppercase">{{ cliente_encontrado }}</p>

            <v-row>
              <v-col cols="5">
                <v-btn block color="warning" outlined type="button" @click="limpiarModal()">
                  <v-icon left small>mdi-close</v-icon>
                  Buscar otro cliente
                </v-btn>
              </v-col>
              <v-col cols="7">
                <v-btn block color="success" @click="actualizarClienteRetiro">
                  <v-icon left small>mdi-check</v-icon>
                  Confirmar cliente
                </v-btn>
              </v-col>
            </v-row>

          </div>
        </v-fade-transition>


        <!--        <pre>-->
        <!--          {{caratula}}-->
        <!--        </pre>-->
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>

// eslint-disable-next-line no-unused-vars
import usuarioEntity from "@/model/usuario/usuario.entity";
import caratulaEntity from "@/model/caratula/caratula.entity";
import {rutClean, rutFormat, rutValidate} from "rut-helpers";
import {required} from "vuelidate/lib/validators";
import validEntity from "@/model/validaciones/valid.entity";

const touchMap = new WeakMap();
export default {
  name: "ModalRetiroTercero",
  components: {},
  props: {
    verDialog: {default: false},
    time: {default: null},
    caratula: {default: null},
  },
  data() {
    return {
      ver: false,
      rut: null,
      cliente_buscado: false,
      cliente_encontrado: null,
      rutValidate: rutValidate,
      rutClean,
      errors: validEntity
    }
  },
  validations: {
    rut: {required, rutValidate},
  },
  watch: {


    verDialog() {
      this.ver = this.verDialog;

      if (this.time != null) {
        if (!isNaN(this.time)) {
          let autoclose = parseInt(this.time) * 1000;
          setTimeout(() => this.hideDialog(), autoclose)
        }

      }
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      }
    },

  },
  methods: {
    delayTouch($v) {
      $v.$reset()
      if (touchMap.has($v)) {
        clearTimeout(touchMap.get($v))
      }
      touchMap.set($v, setTimeout($v.$touch, 0))
    },

    formatRut() {
      this.rut = rutFormat(this.rut);
    },

    actualizarClienteRetiro() {

      let rutLimpio = rutClean(this.rut) + "";
      rutLimpio = rutLimpio.substring(0, rutLimpio.length - 1);

      caratulaEntity.updateClienteRetiroCaratula(rutLimpio, this.caratula.numero_caratula,).then(res => {

        this.$emit("updateClienteRetiro", res.cliente_retiro)
        this.hideDialog();
      })
    },

    limpiarModal() {
      this.rut = null;
      this.cliente_buscado = false;
      this.cliente_encontrado = null;
      setTimeout(() => {
        document.getElementById("buscadorCliente").focus();
      }, 500)

    },

    validarCliente() {

      let rutLimpio = rutClean(this.rut) + "";
      console.log("rut limpio: ", rutLimpio)
      rutLimpio = rutLimpio.substring(0, rutLimpio.length - 1);
      usuarioEntity.getNombreUsuarioByRut(rutLimpio).then(res => {
        this.cliente_buscado = true;
        this.cliente_encontrado = res;
      })

    },
    hideDialog() {
      this.rut = null;
      this.cliente_buscado = false;
      this.cliente_encontrado = null;
      this.$emit("ocultarDialog", false);
    },

  }
}
</script>

<style scoped>

</style>
