<template>
  <div>
    <v-row>
      <v-col cols="12">
        <v-data-table

            :headers="headers"
            :items="misCompras"
            :loading="cargandoCompras"
            loading-text="Cargando sus compras... espere un momento"
            :item-class="itemRowBack"
            :footer-props="{
              'items-per-page-text': 'Ítems por página',
              'items-per-page-all-text': 'Todo',
            }"
        >

          <!-- PARTE SUPERIOR DE LA TABLA -->

          <template v-slot:top>
            <v-toolbar
                flat
            >
              <v-toolbar-title>
                <p class="d-flex align-center mb-0">
                  <v-icon color="info" class="pr-1">mdi-cart</v-icon>
                  Mis Compras
                </p>
              </v-toolbar-title>
              <v-divider
                  class="mx-4"
                  inset
                  vertical
              ></v-divider>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>

          <template v-slot:header>

          </template>


          <!-- FECHA DE CREACION DEL CARRO -->

          <template v-slot:item.fecha_creacion="{item}" style="background-color: red">

            <span>{{ item.fecha_creacion | fullDate }}</span>

          </template>

          <!--     PRECIO DEL DOCUMENTO -->
          <template v-slot:item.precio_total="{item}">
            <div>
              <span v-if="!pagoSolicitudesValido(item.solicitudes_cliente)">Por cotizar</span>
              <span v-if="pagoSolicitudesValido(item.solicitudes_cliente)">{{ item.precio_total | clp }}</span>
            </div>
          </template>

          <!-- ESTADO DE PAGO -->

          <template v-slot:item.pagado="{item}">

            <v-icon v-if="item.pagado && carroEntity.verificarSolicitudesDeCarroPagadas(item)" color="success">
              mdi-check-circle-outline
            </v-icon>
            <v-icon v-if="item.pagado && !carroEntity.verificarSolicitudesDeCarroPagadas(item)" color="warning">
              mdi-check-circle-outline
            </v-icon>

            <v-btn v-if="!item.pagado" outlined color="info"
                   :disabled="!pagoSolicitudesValido(item.solicitudes_cliente)"
                   small
                   @click="continuarCompra(item)">Ir a pagar
              <v-icon class="pl-1" small color="info">mdi-cart-arrow-right</v-icon>
            </v-btn>
          </template>

          <!-- BOTON PARA VER LOS DOCUMENTOS -->

          <template v-slot:item.ver="{item}">

            <a @click="verDetalle(item)">
              <v-icon color="success">mdi-text-box-search-outline</v-icon>
            </a>

          </template>

          <!-- TABLA SIN DATOS -->

          <template v-slot:no-data>
            No se han registrado compras
          </template>

          <template #footer.page-text="props">
            {{ props.pageStart }} al {{ props.pageStop }} de {{ props.itemsLength }}
          </template>

        </v-data-table>
      </v-col>
    </v-row>
    <modal-continuar-compra :ver-dialog="verDialogContinuarCompra"
                            :subtotal="compraModalPago.subtotal"
                            :solicitudes="compraModalPago.solicitudes"
                            @actualizarEstadoCompra="actualizarCompraSeleccionada"
                            :id_carro_solicitud_cliente="compraModalPago.id_carro_solicitud_cliente"
                            :total_carro="compraModalPago.precio_total"
                            @ocultarDialog="verDialogContinuarCompra = false"></modal-continuar-compra>
    <compras-dialog-ver-solicitudes
        :precio_cotizado="pagoSolicitudesValido(compraSeleccionada != null ? compraSeleccionada.solicitudes_cliente : [])"
        :compra="compraSeleccionada" :ver-dialog="verDialogCompra"
        @continuarCompra="continuarCompra($event)"
        @ocultarDialog="verDialogCompra = false"></compras-dialog-ver-solicitudes>
  </div>

</template>

<script>

import usuarioEntity from "@/model/usuario/usuario.entity";
import ComprasDialogVerSolicitudes from "@/views/index/cliente/compras/ComprasDialogVerSolicitudes";
import ModalContinuarCompra from "@/views/index/cliente/compras/modals/ModalContinuarCompra";
import carroEntity from "@/model/carro/carro.entity";

export default {
  name: "ComprasView",
  components: {ModalContinuarCompra, ComprasDialogVerSolicitudes},
  data: () => ({
    headers: [
      {text: 'N° de carro de compra', align: 'center', value: 'id_carro_solicitud_cliente', sortable: false},
      {text: 'Fecha creación', value: 'fecha_creacion', align: 'center', sortable: false},
      {text: 'Total carro', value: 'precio_total', align: 'center', width: '10%', sortable: false},
      {text: 'Ver contenido', value: 'ver', align: 'center', sortable: false},
      {text: 'Estado', value: 'pagado', align: 'center', sortable: false},
    ],
    carroEntity: carroEntity,
    cargandoCompras: false,
    misCompras: [],
    verDialogCompra: false,
    compraSeleccionada: null,
    verDialogContinuarCompra: false,
    compraModalPago: {},
  }),

  computed: {},

  watch: {},

  created() {
    this.cargandoCompras = true;
    usuarioEntity.getMisCompras().then(r => {
      this.cargandoCompras = false;
      this.misCompras = r;

    })
  },

  methods: {
    actualizarCompraSeleccionada(id){
      console.log("intenta actualizar la compra")
      this.misCompras.find(f => f.id_carro_solicitud_cliente === id).pagado = true;
      this.compraSeleccionada.pagado = true;
    },
    pagoSolicitudesValido(solicitudes) {
      if (solicitudes == null) {
        return false
      }
      let puede_pagar = true;
      console.log("solicitudes: ", solicitudes)
      if (solicitudes.length == 0) {
        return false;
      }

      solicitudes.map(sol => {
        if (sol.precio_total <= 0) {
          puede_pagar = false
        }
      })
      return puede_pagar
    },
    continuarCompra(item) {
      let precio = 0;

      item.solicitudes_cliente.map(sol => {
        sol.documentosSolicitud.map(doc => {
          precio += doc.precio_pagado;
        })
      })
      this.compraModalPago.id_carro_solicitud_cliente = item.id_carro_solicitud_cliente
      this.compraModalPago.subtotal = precio;
      this.compraModalPago.solicitudes = item.solicitudes_cliente;
      this.compraModalPago.precio_total = item.precio_total;
      this.verDialogCompra = false;
      this.verDialogContinuarCompra = true;

    },

    verDetalle(item) {
      this.compraSeleccionada = item;
      this.verDialogCompra = true;
    },
    itemRowBack(item) {
      if (this.compraSeleccionada != null) {
        if (this.compraSeleccionada.id_carro_solicitud_cliente === item.id_carro_solicitud_cliente) {
          return "blue lighten-5"
        }
      }

    }
  },
}
</script>

<style scoped>

</style>
