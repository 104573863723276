<template>
  <v-dialog max-width="570" v-model="ver" persistent>
    <v-card>
      <v-card-text v-if="!validandoPago && !carro_pagado">
        <v-row v-if="!generandoSolicitudes">
          <v-col cols="12">
            <modal-pago-medios-pago v-if="!load.tipos_pagos" :ayuda="true"
                                    :precio_total_carro="total_carro"
                                    :loading="load.tipos_pagos"
                                    :ocultarOpciones="tipos_pagos_deshabilitados"
                                    :key="openCount"
                                    @updateTipoPago="updateTipoPago($event)"></modal-pago-medios-pago>
            <v-divider></v-divider>
          </v-col>


          <v-col cols="12" sm="7" class="pl-sm-7 pt-0">

            <p style="line-height: 17px; font-size: 11px;"
               class="font-sm mb-0 text-center">La comisión del recaudador ya se encuentra incluida en el precio
              total</p>
            <!-- <p class="font-sm mb-0" v-if="hasChilexpress">Envío: $3.500</p>
     <p class="font-sm mb-0">Cargos operacionales khipu: {{ comision | clp }}</p>
     -->
          </v-col>
          <v-col cols="12" sm="5" class="d-flex justify-end pr-sm-7 pt-3 pt-sm-0">
            <p class="font-sm mb-0 mt-auto font-weight-bold" style="font-size: 16px">Total: {{
                total_carro | clp
              }}</p>
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn color="grey" outlined class="mr-3" @click="hideDialog()" :disabled="generandoSolicitudes" block>
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>

            <!--            <p class="text-center font-md error&#45;&#45;text mb-0" v-if="errorFormulario != null">{{ errorFormulario }}</p>-->
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn color="success" outlined class="px-10" :disabled="tipo_pago_seleccionado == null"
                   @click="enviarFormulario()"
                   :loading="generandoSolicitudes" block>
              <v-icon class="pr-1" small>mdi-credit-card-multiple-outline</v-icon>
              Pagar
            </v-btn>
          </v-col>
        </v-row>

        <div v-show="generandoSolicitudes">
          <modal-pago-pantalla-carga :tipo_pago_seleccionado="tipo_pago_seleccionado"></modal-pago-pantalla-carga>
        </div>

      </v-card-text>
      <v-card-text v-if="!validandoPago && carro_pagado">

        <div class="text-center">
          <v-icon x-large color="success" class="pt-3">mdi-check-all</v-icon>
        </div>
        <p class="text-center pt-3">
          Pago Verificado
        </p>
        <div class="text-center">
          <v-btn color="success" outlined class="px-10" small @click="hideDialog()" :disabled="generandoSolicitudes">
            <v-icon small left>mdi-close</v-icon>Volver
          </v-btn>
        </div>
      </v-card-text>
      <v-card-text v-if="validandoPago">
        <div class="text-center">
          <p class="text-center pt-5 pb-3">Verificando el estado de su compra, por favor, espere un momento...</p>
          <v-progress-circular
              :size="50"
              color="primary"
              class="mb-4"
              indeterminate
          ></v-progress-circular>
        </div>
        <v-row>
          <v-col cols="6" class="text-right">
            <v-btn color="grey" outlined class="mr-3" @click="hideDialog()" :disabled="generandoSolicitudes" block>
              <v-icon small>mdi-close</v-icon>
              Volver
            </v-btn>

            <!--            <p class="text-center font-md error&#45;&#45;text mb-0" v-if="errorFormulario != null">{{ errorFormulario }}</p>-->
          </v-col>
          <v-col cols="6" class="text-right">
            <v-btn color="success" outlined class="px-10" disabled block>
              <v-icon class="pr-1" small>mdi-credit-card-multiple-outline</v-icon>
              Pagar
            </v-btn>
          </v-col>

        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import ModalPagoMediosPago from "@/views/index/carro/modal-pago/ModalPagoMediosPago";
import usuarioEntity from "@/model/usuario/usuario.entity";
import carroEntity, {ID_TIPO_PAGO, ID_TIPO_RETIRO} from "@/model/carro/carro.entity";
import ModalPagoPantallaCarga from "@/views/index/carro/modal-pago/ModalPagoPantallaCarga";

export default {
  name: "ModalContinuarCompra",
  components: {ModalPagoPantallaCarga, ModalPagoMediosPago},
  props: {
    verDialog: {default: false},
    time: {default: null},
    subtotal: {default: 0},
    solicitudes: {default: null},
    id_carro_solicitud_cliente: {default: 0},
    total_carro: {defaul: 0}
  },
  data() {
    return {
      ver: false,
      comunas: [],
      isLoading: false,
      formularioValido: false,
      dataFormulario: {},
      valido: false,
      errorFormulario: null,
      precio_total_carro: 0,
      comision: 0,
      tipo_retiro: null,
      ID_TIPO_RETIRO: ID_TIPO_RETIRO,
      generandoSolicitudes: false,
      hasChilexpress: false,
      ID_TIPO_PAGO: ID_TIPO_PAGO,
      tipos_pagos_deshabilitados: [],
      tipos_pagos: [],
      load: {
        tipos_pagos: false
      },
      openCount: 0,
      tipo_pago_seleccionado: null,
      validandoPago: false,
      carro_pagado: false
    }
  },

  created() {

    this.isLoading = true
    usuarioEntity.getAllComunasDeRegiones(this.search).then(response => {
      this.comunas = response;
    }).catch(error => {
      console.log("Error: ", error)
    }).finally(() => (this.isLoading = false))
  },
  computed: {},
  watch: {
    verDialog() {
      this.ver = this.verDialog;
    },

    solicitudes() {
      this.checkPlanos()
    },

    ver(newVal) {
      if (!newVal) {
        this.$emit("ocultarDialog", false);
      } else {
        this.openCount++; // se utiliza para refrescar la key del modal
        this.tipo_pago_seleccionado = null;


        if (this.tipos_pagos.length == 0) {

          this.load.tipos_pagos = true;

          carroEntity.getTiposPagos().then(res => {
            this.load.tipos_pagos = false;
            this.tipos_pagos = res;
            this.getPagosDeshabilitados();

          })

        } else {
          this.getPagosDeshabilitados();
        }
      }


    },
    tipo_retiro() {
      this.getPrecioTotal();
    },
    subtotal() {
      this.getPrecioTotal();
    }
  },

  methods: {
   //TODO: usar este commit, no el anterior
    getPagosDeshabilitados() {
      let comision = this.tipos_pagos.find(t => t.id_tipo_pago === parseInt(this.solicitudes[0].id_tipo_pago_FK));
      this.tipos_pagos_deshabilitados = [];
      this.tipos_pagos.map(tip => {
        if (tip.comision != comision.comision) {
          this.tipos_pagos_deshabilitados.push(tip);
        }
      })
      let tgr = this.tipos_pagos_deshabilitados.find(t => t.id_tipo_pago === ID_TIPO_PAGO.TGR);

      if (tgr != null) {
        this.carro_pagado = false;
      } else {

        this.validandoPago = true;
        carroEntity.validarPagoTgrByCarro(this.id_carro_solicitud_cliente).then(res => {
          this.validandoPago = false;
          this.carro_pagado = !!res;

          if(this.carro_pagado){
            this.$emit("actualizarEstadoCompra", this.id_carro_solicitud_cliente);
          }
        })
      }

    },
    getTiposPagos() {
      this.load.tipos_pagos = true;
      carroEntity.getTiposPagos().then(res => {
        this.load.tipos_pagos = false;
        this.tipos_pagos = res;


      })


    },
    checkPlanos() {
      this.hasChilexpress = false;
      this.solicitudes.map(sol => {
        if (sol.id_tipo_retiro_FK == ID_TIPO_RETIRO.CHILEXPRESS) {
          this.hasChilexpress = true;
        }
      })
    },
    setTipoRetiro(tipo_retiro) {
      this.tipo_retiro = tipo_retiro;
    },

    isFormularioValido() {
      if (this.dataFormulario == null) {
        return false;
      }
      return this.dataFormulario.id_tipo_pago != null;
    },

    getPrecioTotal() {
      // let tot = this.subtotal;
      // if (this.hasChilexpress) {
      //   tot = this.subtotal + 3500;
      // }
      // if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU) {
      //   tot = parseInt(tot * carroEntity.COMISION.KHIPU)
      // }
      // if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU_WEB) {
      //   tot = parseInt(tot * carroEntity.COMISION.KHIPU_WEB)
      // }
      //
      // this.precio_total_carro = tot
      // this.getComision();
    },
    getComision() {
      //
      // let tot = this.subtotal;
      // if (this.hasChilexpress) {
      //   tot = this.subtotal + 3500;
      // }
      // if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU) {
      //   this.comision = parseInt(tot * (carroEntity.COMISION.KHIPU - 1))
      // }
      // if (this.dataFormulario.id_tipo_pago === ID_TIPO_PAGO.KHIPU_WEB) {
      //   this.comision = parseInt(tot * (carroEntity.COMISION.KHIPU_WEB - 1))
      // }

    },
    hideDialog() {
      this.$emit("ocultarDialog", false);
    },
    updateTipoPago(id_tipo_pago) {
      console.log("actualizando el tipo de pago")
      this.tipo_pago_seleccionado = id_tipo_pago;
      // this.valido = this.isFormularioValido();
      // this.getPrecioTotal();
    },

    enviarFormulario() {
      this.generandoSolicitudes = true;
      this.$emit("generandoCarro", null);
      carroEntity.continuarPagoCarro(this.id_carro_solicitud_cliente, this.tipo_pago_seleccionado).then(resp => {

        window.location = resp;

      }).catch(error => {
        this.errorFormulario = error.mensaje;
      }).finally(() => {
        /*this.generandoSolicitudes = false;*/
      });
    }
  }
}
</script>

<style scoped>

</style>
